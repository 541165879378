// @import '../../swiper-vars.scss';
// https://github.com/nolimits4web/swiper/blob/master/src/modules/navigation/navigation.scss

:root {
  --swiper-navigation-size: 35px;
  /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
  --swiper-navigation-color: rgba(0, 0, 0, 0.5);
  --swiper-navigation-color-hover: rgba(0, 0, 0, 1);
}
.swiper-button--prev,
.swiper-button--next {
  position: absolute;
  top: 50%;
  // width: calc(var(--swiper-navigation-size) / 44 * 27);
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  transition: color 0.3s;
  &:hover {
    color: var(--swiper-navigation-color-hover);
    // color: #000;
  }
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  //   &:after {
  //     font-family: swiper-icons;
  //     font-size: var(--swiper-navigation-size);
  //     text-transform: none !important;
  //     letter-spacing: 0;
  //     text-transform: none;
  //     font-variant: initial;
  //     line-height: 1;
  //   }
}
.swiper-button--prev,
.swiper-rtl .swiper-button--next {
  //   &:after {
  //     content: "prev";
  //   }
  // left: 10px;
  right: auto;
}

.swiper-button--next,
.swiper-rtl .swiper-button--prev {
  //   &:after {
  //     content: "next";
  //   }
  right: -22px;
  left: auto;
}

@media screen and (min-width: 500px), print {
  .swiper-button--next,
  .swiper-rtl .swiper-button--prev {
    //   &:after {
    //     content: "next";
    //   }
    right: 30px;
    left: auto;
  }
}

.swiper-button-lock {
  display: none;
}
