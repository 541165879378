$header-show-nav--width: 768px
.header__panel
    display: flex
    flex-direction: row
    align-items: center

// ---------------------
// logo
// ---------------------
.header-brand
    margin-right: auto
    // +desktop
    // +tablet
    @media screen and (min-width: $header-show-nav--width)
        margin-right: 0
// ---------------------
// Nav
// ---------------------
.nav__wrap
    margin-left: auto
    margin-right: auto
    display: none
    // +tablet
    @media screen and (min-width: $header-show-nav--width)
        display: block

.main-nav__list
    list-style: none
    line-height: 1
    // display: none
    display: flex

.main-nav__link
    display: block
    position: relative
    font-size: 1rem
    color: $gray-prime
    margin-left: 1rem
    margin-right: 1rem
    font-weight: 500
    transition: color 0.3s
    &:hover
        color: $watermelon
    +tablet-only
        font-size: 0.84rem
        margin-left: 0.7rem
        margin-right: 0.7rem
.active
    .main-nav__link
        &::after
            position: absolute
            content: ""
            display: block
            bottom: -3px
            left: 0
            width: 100%
            height: 1px
            // background-color: #fae053
            background-color: #ffde17
            transition: all 0.3s
        &:hover::after
            background-color: $watermelon

// ---------------------
// action button
// ---------------------
.action__button--header
    display: none
    line-height: 1
    padding: 0.7rem 1rem
    // border: solid 2px var(--watermelon)
    font-size: 1rem
    color: $gray-prime
    font-weight: 600
    background-color: $yellow
    border: 2px solid $yellow
    transition: all 0.3s
    outline: none
    +tablet
        padding: 0.7rem 2rem
    +desktop
        padding: 0.7rem 3.25rem
    &:hover
        background-color: transparent
        color: rgba($gray-prime, 0.85 )
        box-shadow: 0 5px 15px 0 rgba(89,69,45,0.1)
        cursor: pointer
        // color: #000
    @media screen and (min-width: 480px)
        display: block

.action__button--header-small
    border: 1px solid $watermelon
    display: flex
    align-items: center
    justify-content: center
    background-color: #FFF
    border-radius: 50%
    width: 2.6rem
    height: 2.6rem
    color: red
    margin-top: -3px
    transition: all 0.3s
    outline: none
    // flex: 0 0 5rem
    // margin-right: 1.3rem
    &:hover
        background-color: $watermelon
        color: rgba(255, 255, 255, 0.85)
        box-shadow: 0 5px 15px 0 rgba(89,69,45,0.1)
        cursor: pointer
        .action__icon
            fill: #FFF
    @media screen and (min-width: 480px)
        display: none
    .action__icon
        width: 78%
        height: 78%
        // fill: $gray-prime
        fill: rgba($gray-prime, 0.85 )
        // stroke: #ff4d6b
        path
            // fill: currentColor !important
            fill: inherit

.header__container
    padding-left: 1rem
    padding-right: 1rem
+desktop-only
    .header__container
        max-width: none
        padding-left: $gap
        padding-right: $gap
        width: 100%
