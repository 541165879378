// сторінка продукту
$space-for-variation-title: 1.8rem
// $variation-color-size: 1.9rem
$variation-color-size: 30px

.section--product-page
    padding: 2rem 1rem
    +desktop
        padding: 2rem 1.5rem

.product
    position: relative

+mobile
    .product__gal
        margin-top: 5rem
.product__info
    +tablet
        padding-left: 2rem
    .i-product__variations
        margin-bottom: 1.5rem
        position: relative
        padding-top: $space-for-variation-title
        margin-right: 1.4rem
    .price-wrap
        margin-bottom: 1.5rem
    +tablet
        .i-product__variations, .price-wrap
            margin-bottom: 2rem
        .i-product__buy
            margin-bottom: 2rem
    .product__params
        color: $gray-text-light

.product__title
    font-size: 2rem
    font-weight: 700
    color: $dark1
    text-transform: uppercase
    margin-bottom: 2rem
    letter-spacing: .4px
    +mobile
        position: absolute
        top: 0
        left: 3rem
        margin-bottom: 0.5rem
        display: flex
        min-height: 5rem
        align-items: center
        line-height: 1.3

.product__content
    font-size: 1.06rem
    font-weight: 500
    max-width: 526px
    div
        margin-bottom: 1rem

.breadcrumb
    +mobile
        font-size: 0.8rem
    ul
        display: inline-flex
        flex-wrap: wrap
        background-color: #f1f5f5
        padding: .5rem 1rem .5rem 1.5rem
        // margin: 1rem 0;
    a
        transition: 0.3s
    .is-active
        font-weight: 700
        // a
        //     color: #7d7d7d

.color-attributes
    align-items: center
    display: flex
    flex-wrap: nowrap
    justify-content: flex-start

.color-attribute
    display: inline-flex
    justify-content: flex-start
    // margin-right: 0.7rem
    margin-right: 0.5rem

.btn--color-attribute
    // display: inline-flex
    display: flex
    justify-content: center
    align-items: center
    box-shadow: none
    font-size: 1rem
    // height: 2.5em
    line-height: 1
    position: relative
    // vertical-align: top
    padding: 0
    height: $variation-color-size
    width: $variation-color-size
    border-radius: 50%
    border: 1px solid transparent
    // background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59))
    // background-color: none
    background-color: rgba(239, 239, 239, 0.6)
    // background-color: transparent
    transition: border-color .25s
    // transition: color 0.25s
    &:hover
        border-color: rgb(180, 180, 180)
    &.is-selected
        border-color: $active-color-border
        box-shadow: 0 6px 6px hsl(0deg 0% 76% / 50%)

    &::before
        display: block
        content: ""
        // 1.625rem
        // width: 1.5rem
        width: calc( #{$variation-color-size} - 8px )
        height: calc( #{$variation-color-size} - 8px )
        background-color: #000
        border-radius: 50%

.color-attribute--
    &black
        &::before
            background-color: #000000
    &red
        &::before
            background-color: #ff0000
    &yellow
        &::before
            background-color: #ffff00
    &white
        &::before
            background-color: #FFFFFF
    &brown
        &::before
            background-color: #8B4513
    &powder
        &::before
            background-color: #E0AB99
    &crimson
        &::before
            background-color: #B64C67
    &deep-blue
        &::before
            background-color: #2D435C
    &black-overflow
        &::before
            background-color: #272727
    &orange
        &::before
            background-color: #FF9200

.variations-info
    display: flex
    align-items: flex-start

.price-wrap
    display: flex
    // align-items: flex-end
    align-items: baseline
    position: relative
    padding-top: $space-for-variation-title
    // margin-bottom: 2rem

    .price
        font-size: 1.25rem
        font-weight: 600
        // color: $dark1
        // color: $gray-price
        color: #000000
        white-space: nowrap
        // padding-left: 0.6rem
        line-height: $variation-color-size

    .price-old
        position: relative
        margin-left: 0.5rem
        margin-right: 0.8rem
        font-size: 1rem
        font-weight: 500
        // color: #A6A6A6
        // text-decoration: line-through
        white-space: nowrap
        // line-height: $variation-color-size
        line-height: 1
        &::after
            content: ""
            position: absolute
            // left: -4px
            width: calc(100% + 11px)
            height: 2px
            background-color: #ff5536
            left: -5px
            top: 44%

.price-title, .color-list-title
    position: absolute
    top: 0
    left: 0
    font-weight: 400
    font-size: 1rem
    color: #44403F

+tablet
    .product__info-inner--sticky
        position: sticky
        top: 1rem
    .product
        margin-top: 2.5rem

// пробував робити галерею без слайдера
// щоб картинки скролились і фіксувались зверху
// 2024-07 Зіна попросила щоб картинки не наїзжали одна на одну
// .img-wrap__default
//     position: sticky
//     top: calc(1rem - 1px)
//     padding-bottom: 0.75rem
//     // щоб при скролі знизу непросвічувалось зафіксоване фото
//     background-color: #fff
//     // &:first-child::before
//     &.is-pinned::before
//         // content: ""
//         width: 100%
//         height: 1rem
//         top: -1rem
//         background-color: #FFF
//         position: absolute
//         z-index: -1
//     img
//         display: block
//         margin: 0 auto
//         // box-shadow: 0 -10px 20px rgb(0 0 0 / 10%)
//         box-shadow: 0 0px 20px rgb(0 0 0 / 10%)

.img-wrap__default
    // position: sticky
    // top: calc(1rem - 1px)
    padding-bottom: 0.75rem
    padding-top: 0.75rem
    // &:first-child::before
    img
        display: block
        margin: 0 auto
        // box-shadow: 0 -10px 20px rgb(0 0 0 / 10%)
        box-shadow: 0 0px 20px rgb(0 0 0 / 10%)
.img-wrap.hide-it
    visibility: hidden

+mobile
    .product__summary
        display: flex
        flex-wrap: wrap
        // background-color: #F9FAFB
        // background-color: #F9F9F9
        // background-color: #FFF
        // background-color: #FFFCF6
        background-color: #fffffd
        position: fixed
        z-index: 100
        bottom: 0
        left: 0
        width: 100%
        padding: 1.5rem 1.5rem
        box-shadow: 0 -10px 20px rgb(0 0 0 / 10%)
        .i-product__buy
            flex-grow: 2
        .price-wrap
            min-width: 10rem
            margin-right: 1.5rem
    .button.buy-button
        width: 100%
        min-width: 10rem !important
