
$footer-link-color: #000
// $footer-link-color: rgba(0, 0, 0, 0.8)
// $footer-hover-color: $watermelon
$footer-hover-color: $yellow-1
.footer
    padding: 3rem 1.5rem
    background-color: #F9F9F9
    font-size: 0.875rem
    color: $grey-darker
    +tablet
        padding: 5rem 1.5rem
.footer__logo
    transition: color 0.3s
    font-size: 1.8rem
    // color: $watermelon
    // color: $yellow
    color: $footer-link-color
    font-weight: bold
    letter-spacing: 1px
    text-transform: uppercase
    +tablet
        font-size: 2.25rem
    &:hover
        color: $footer-hover-color


.copy-text__col
    align-self: flex-end

.footer__instruction
    margin-bottom: 1rem
    margin-top: 1rem
    a
        border-bottom: 1px solid rgba(0,0,0,0.1)
        color: $gray-blue
        transition: all 0.3s
        &:hover
            color: $watermelon
            border-bottom: 1px solid $watermelon
.footer__contacts
    .intro-text
        margin-bottom: 1rem
    .contact__link
        color: $footer-link-color
        transition: all 0.3s
        display: block
        &:hover
            // color: rgba($watermelon, 0.9)
            color: $yellow
.tel__link
    font-size: 1.8rem
    line-height: 1
    font-weight: 600
    margin-bottom: 0.3rem
    +tablet
        font-size: 2.25rem
.mail__link
    font-size: 1rem
    font-weight: 500
    +tablet
        font-size: 1.2rem

.footer__social
    .intro-text
        margin-bottom: 1rem
        text-align: center
        +touch
            display: none

// .footer__social-intro
//     display: none
//     +tablet
//         display: block

.social__items
    display: flex
    align-content: center
    flex-direction: column
    flex-wrap: wrap
    justify-content: center
    +tablet
        flex-direction: row

// +mobile
+touch
    .footer-social__col
        position: absolute
        top: 0
        right: 0
