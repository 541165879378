@charset "utf-8"

// @import "./app.css"

// custom hamburger
@import "./parts/hamburgers.sass"

// @import "/node_modules/bulma/bulma.sass"
// bulma helpers
@import "/node_modules/bulma/sass/utilities/functions"

@import "main-variables.sass"
@import "./libs/bulma/bulma.sass"

// https://swiperjs.com/
@import "./libs/swiper/swiper.scss"

@import "./parts/header.sass"
// @import "./parts/hero.sass"
// @import "./parts/prevents.sass"
// @import "./parts/section-about.sass"
// @import "./parts/section-benefits.sass"
// @import "./parts/section-components.sass"
// @import "./parts/section-checkout.sass"
@import "./parts/footer.sass"

// for product page
@import "./parts/product.sass"
// for products grid
@import "./parts/product-list.sass"
@import "./parts/cart-list.sass"

@import "./parts/modal-cart.sass"
// @import "./parts/modal-call-me.sass"
@import "./parts/modal-video.sass"

@import "./parts/checkout.sass"
// body
//     background-color: #000
html
    scroll-behavior: smooth
.label
    // color: $label-color
    font-size: 0.9rem
    // font-weight: $label-weight
    &:not(:last-child)
        margin-bottom: 0.2em

.field
    &:not(:last-child)
        margin-bottom: 1rem

.input, .textarea
    font-weight: 500
    letter-spacing: 0.5px

.message.is-danger
    color: $red
    margin-top: 1rem
    margin-bottom: 1rem

.disable-select
    // для swiper-button--prev disable-select
    // коли декілька разів нажимав на кнопу, виділявся для копіювання слайдер
    user-select: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none

.modal
    &.is-active
        z-index: 110

.button
    cursor: pointer
    border: 1px solid transparent
    .icon--svg
        fill: currentColor
        height: 19px
        width: 19px
        // height: 19px
        // width: 19px
        margin-right: 0.5rem
        margin-left: -0.5rem
        margin-top: -3px
        // &:first-child:not(:last-child)
        //     +ltr-property("margin", calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width}), false)
        //     +ltr-property("margin", $button-padding-horizontal / 4)
        // &:last-child:not(:first-child)
        //     +ltr-property("margin", $button-padding-horizontal / 4, false)
        //     +ltr-property("margin", calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width}))
        // &:first-child:last-child
        //     margin-left: calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width})
        //     margin-right: calc(#{-1 / 2 * $button-padding-horizontal} - #{$button-border-width})