// сторінка категорії
.section--products-category
    padding: 2rem 1.5rem

.button
    &.buy-button
        position: relative
        display: inline-flex
        // align-items: baseline
        align-items: center
        justify-content: center
        vertical-align: top
        text-align: center
        white-space: nowrap
        border-color: $dark1
        background-color: transparent
        border-radius: 0
        // color: #000
        color: rgba(0, 0, 0, 0.7)
        // font-weight: 700
        // font-size: 1rem
        // font-size: 0.875
        font-weight: 500
        // font-weight: 400
        letter-spacing: 0.5px
        // border-color: #FFE18E
        transition: all 0.3s ease-in-out
        text-transform: uppercase
        padding-bottom: 0.75rem
        // padding-top: 0.7rem
        padding-top: calc(0.75rem + 1px)
        padding-left: 1em
        padding-right: 1em
        min-width: 16rem
        max-width: 100%
        &:hover
            color: rgba(#FFF, 0.9)
            background-color: $watermelon
            border-color: $watermelon
            box-shadow: 0 6px 6px hsl(0deg 0% 76% / 50%)
            // font-weight: bold

.item-product
    // padding-top: 1rem
    // padding-bottom: 1rem
    padding-top: 2rem
    padding-bottom: 2rem
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 1.5rem 1rem
    transition: box-shadow 0.5s ease-in-out
    &:hover
        // box-shadow: 0 0 30px rgba(0,0,0,.2)
        .button.buy-button
            color: rgba(#FFF, 0.9)
            background-color: $dark-red
            border-color: $dark-red
        .i-product__title a
            background-size: 100% 50%
        +tablet
            box-shadow: 0 0 30px rgba(0,0,0,.2)
    .button.buy-button:hover
        color: rgba(#FFF, 1)

    // .product-img
    //     background-color: #000

// i-  short from -> item-
.i-product__header
    position: relative
.i-product__img
    padding-bottom: 2.5rem
// global style
// зменшував фото для піци щоб не було таким великим
// якщо так зменшувати треба подумати про блок aspect ratio
// .img-wrap__pizza
//     text-align: center
//     img
//         max-width: 80%
//         margin-left: auto
//         margin-right: auto
.i-product__title
    position: absolute
    width: 100%
    left: 0
    bottom: 0
    font-size: 1.4rem
    // font-weight: 500
    font-weight: 600
    color: #000
    // margin-top: 1.2rem
    line-height: 1.2
    // margin-bottom: 0.5rem
    a
        // color: #000
        color: #333f48
        // https://www.codegram.com/case-studies/
        background-image: linear-gradient(90deg, #fdf4aa 0, #fdf4aa)
        background-repeat: no-repeat
        background-position-y: 100%
        background-size: 0 50%
        transition: background .5s ease
        &:hover
            // color: $red
            color: #333f48
            background-size: 100% 50%
    // v1
    // .product__title
    //     font-size: 1.5rem
    //     font-weight: 500
    //     color: #000
    //     margin-top: 1.2rem
    //     line-height: 1.2
    //     margin-bottom: 0.5rem
    //     a
    //         color: #000
    //         &:hover
    //             color: $red

.i-product-description
    position: relative
    font-size: 0.875rem
    // line-height: 18px;
    line-height: 1.4
    // color: gray
    color: $gray-text-light
    margin-top: 0.8rem
    margin-bottom: 1rem
    // todo: add boottom shadow https://mafia.ua/ua/kiev/menu-dostavki/salaty
    +tablet
        // height: 54px
        height: 3.75rem
        overflow: hidden
        &::after
            content: ''
            display: block
            position: absolute
            bottom: 0
            right: 0
            left: 0
            height: 25px
            // background: -webkit-linear-gradient(top,rgba(255,255,255,0) 0%,#fff 100%);
            // z-index: 1

.i-product__params
    color: $gray-text-light
    font-size: 0.9rem

.i-product__buy
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-items: center
    margin-top: 0.2rem
    // border: 1px solid $dark1
    .price
        font-size: 1.3rem
        font-weight: 500
        // color: $dark1
        color: $gray-price
        white-space: nowrap
        // padding-left: 0.6rem
    .price-old
        margin-left: 0.5rem
        margin-right: 0.8rem
        font-size: 1rem
        font-weight: 500
        color: #A6A6A6
        text-decoration: line-through
        white-space: nowrap
    // .buy-wrap
    //     margin-left: 0.8rem
    //     flex-grow: 1

// global
.variations__pizza-size
    position: relative
    .selected-info
        position: absolute
        top: 0
        right: 0
        text-align: right
        font-size: $size-small
        padding-top: calc(0.5em - 1px)
        &::before
            transition: all 0.3s ease-in-out
            content: ""
            position: absolute
            border-radius: 50%
            border: 2px solid #FFE18E
            // &::before
            top: -28px
            // bottom: 0px
            right: 0
            width: 30px
            height: 30px
        // &::after
        //     top: -23px;
        //     right: 5px;
        //     width: 20px;
        //     border: 1px solid #FFE18E;
        //     height: 20px;
        &.is-large::before
            top: -40px
            // bottom: 0px
            width: 45px
            height: 45px
    .v-name
        color: rgb(177, 177, 177)
        font-weight: 500
    .button
        transition: all 0.3s ease-in-out
        position: relative
        border-color: #FFE18E
        &:hover
            border-color: #d4b86b
        &.is-selected
            background-color: #FFE18E
            // background-color: #d4b86b
            box-shadow: 0 0 0 0.125em rgba(#d4b86b, 0.25)
            &:hover
                border-color: rgba(0, 0, 0, 0)
        // &.is-selected
        //     &::before
        //         transition: all 0.3s ease-in-out
        //         content: "";
        //         position: absolute;
        //         top: 0
        //         left: 0
        //         width: 30px;
        //         height: 30px;
        //         z-index: 10
        //         background: url(/images/svg/checked-1.svg) 0 5px no-repeat;

    .button:focus, .button:active
        outline: none
        border-color: rgba(0, 0, 0, 0)
    .button:focus:not(:active), .button.is-focused:not(:active)
        // box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
        box-shadow: 0 0 0 0.125em rgba(191, 194, 199, 0.25)

// global
// .is-sale
    // распродажа
    // .price
    //     color: #c00a27

// повідомлення про те що товари незнайдені
.is-empty__error
    text-align: center
    font-size: 2rem
    padding-top: 3rem
    padding-bottom: 3rem
    color: $light-red
    font-weight: 700

// підменю
.sub-menu__panel
    padding-bottom: 2rem
    // overflow-x: hidden
.type-list__filter
    display: flex
    justify-content: center
    flex-wrap: wrap
    border-bottom: 1px solid rgba(0, 0, 0, .05)
    font-size: 0.875rem
    li
        padding-left: 0.5rem
        padding-right: 0.5rem
    a
        display: block
        position: relative
        color: #000
        text-transform: lowercase
        // padding-bottom: 0.5rem
        padding-top: 0.5rem
        padding-bottom: 0.3rem
        white-space: nowrap
        &:hover
            color: $dark-red
    .is-active
        a
            color: $dark-red
            &::after
                position: absolute
                content: ""
                // width: calc(100% - 1.5rem);
                width: 100%
                height: 2px
                background-color: $dark-red
                display: block
                bottom: -2px
    +desktop
        font-size: 1rem
        li
            padding-left: 1rem
            padding-right: 1rem
        a
            padding-top: 0.1rem
            padding-bottom: 0.5rem

// .products__section
//     +tablet-only
//         padding: 1.5rem 3rem
