$oi-text-color: #2E1C00

.modal--video
    .modal-content
        overflow: visible

// .modal-background
//     // background-color: rgba(10, 10, 10, 0.86);
//     background-color: rgba(#2E1C00, 0.7);

.modal
    &.modal--video
        .modal-content
            width: auto
        .modal-background
            background-image: linear-gradient(180deg, #FFEAA5 0%, #FFDA61 100%)
        .modal-close
            transition: all 0.3s
        .modal-close:hover, .modal-close:focus
            background-color: rgba(255, 255, 255, 0.6)
        .modal-close::before, .modal-close::after
            background-color: $gray-prime

.modal__video
    display: flex
    align-items: center
    position: relative
    // background-color: #FFF
    width: 100%
    height: 100%
    // border-radius: 50%
    // box-shadow: 0 5px 15px 0 rgba(89,69,45,0.05)
    // border-top: 100vh solid transparent;
    // border-right: 50vw solid #1B1F25;
    // border-left: 50vw solid #fff;
    // box-shadow: 2px 2px 20px 0 rgba(255,255,255,0.05);
    // &::before
    //     content: ""
    //     position: absolute
    //     width: 116%
    //     height: 116%
    //     left: -8%
    //     top: -8%
    //     border: 1px solid rgba(255, 255, 255, 0.6)
    //     border-radius: 50%
    //     // background: #000
    +tablet
        width: 100%
        height: 100%

.video__content
    margin: 0 auto
    z-index: 1
    // padding: 3rem 3rem 5rem 3rem
    color: rgba($oi-text-color, 0.81)
    // padding: 0 3rem
    padding: 0 0rem
    // @media screen and (min-width: 450px)
    +tablet
        .video__content
            max-width: 100%
            // padding: 3rem 3rem 5rem 3rem
            // padding: 0 4rem
            padding: 0

// .video__title
//     font-size: 1.2rem
//     line-height: 1.3
//     font-weight: 700
//     text-align: center
//     margin-bottom: 1rem
//     color: $watermelon

