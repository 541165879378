.container.container--checkout-page
    max-width: 1344px

.checkout__section
    padding: 3rem 1.5rem
    background-image: linear-gradient(180deg, #FFEAA5 0%, #FFDA61 100%)
    +tablet
        padding: 3.75rem 1.5rem
        min-height: 100vh
        display: flex
        align-items: center
    +desktop
        padding: 5.3rem 1.5rem

.checkout__cart
    --cart-margin--horizontal: 0
    --cart-margin--vertical: 1rem
    @media screen and (min-width: 1024px)
        padding-left: 3rem
    .cart__footer
            --cart-margin--horizontal: 1.4rem

.checkout__content
    position: relative
    background-color: #fff
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.10)
    padding: 1.5rem 1.5rem
    +tablet
        padding: 2.5rem 2rem
        &::before
            content: ""
            position: absolute
            top: 0
            right: 0
            bottom: 0
            width: 50%
            background-color: #F9F9F9
            // z-index: 0
        .step__total
            z-index: 5
    +desktop
        padding: 5.3rem 5.3rem

.checkout-ok__header
    font-size: 1.8rem
    font-weight: 700
    color: $watermelon
    line-height: 1.2
    margin-bottom: 1rem

.contacts__inner
    +tablet
        padding-right: 1.5rem
    +desktop
        padding-right: 3.5rem
.total
    +tablet
        padding-left: 1.5rem
    +desktop
        padding-left: 3.5rem
.checkout__title
    font-weight: 700
    font-size: 1.5rem
    color: $grey-darker
    +tablet
        font-size: 1.7rem
    +desktop
        // font-size: 2rem
        font-size: 1.75rem

.checkout__fields
    margin-top: 1rem
    margin-bottom: 1.5rem

.checkout__button
    background-color: $watermelon
    border: none
    color: rgba(255, 255, 255, 0.96)
    font-weight: 600
    letter-spacing: 0.8px
    // text-align: center;
    padding: 0.9rem 1.5rem
    font-size: 1rem
    line-height: 1
    transition: all 0.25s
    // margin-right: auto
    // margin-left: auto
    display: block
    +tablet
        padding: 0.9rem 3rem
    &:hover
        box-shadow: $box-shadow-hover1

.total-items
    display: flex
    justify-content: space-between
    align-items: flex-end
.price__label
    font-weight: 600
    font-size: 1.2rem
    color: $gray-blue
    margin-bottom: 0.5rem
.total__price
    .price
        color: $grey-darker
        line-height: 1
.price__value
    font-size: 2.2rem
    font-weight: 700
    +tablet
        font-size: 3rem
    +desktop
        font-size: 3.25rem
.price__currency
    font-size: 1rem
    font-weight: 600
    +tablet
        font-size: 1.5rem
    +desktop
        font-size: 2rem

.total__counter
    line-height: 1
    color: $grey-darker

.counter
    display: flex
    align-items: flex-end
    font-size: 1.2rem
    font-weight: 600
.count
    display: flex
    align-items: center
    border: 1px solid $input-border-color
    border-radius: 2px
    background-color: #fff
    button
        border: none
        background-color: #fff
        font-size: 1.2rem
        font-weight: 600
        // color: $watermelon
        color: $grey-dark
        cursor: pointer
        line-height: 2.5rem
        padding: 0
        // padding: 0 0.81rem
        width: 2.5rem
        text-align: center
    .count__value
        display: block
        border-left: 1px solid $input-border-color
        border-right: 1px solid $input-border-color
        line-height: 2.5rem
        // padding-left: 1.2rem
        // padding-right: 1.2rem
        width: 2.5rem
        text-align: center
        +desktop
            // padding-left: 1.7rem
            // padding-right: 1.7rem
            width: 4rem

.counter__label
    font-size: 0.875rem
    font-weight: 500
    padding-bottom: 0.3rem
.count__label
    font-size: 1.2rem
    font-weight: 600
    padding-left: 0.875rem
    padding-bottom: 0.3rem

.total__content
    margin-top: 1.5rem
    padding-top: 1.5rem
    border-top: 1px solid rgba(151,151,151,0.20)
    font-size: 0.875rem
    font-weight: 500
    p
        margin-bottom: 0.5rem

.quantity-info
    color: $dark1
    font-size: 0.875rem
.quantity_label
    // color: $grey-light
    color: $grey

.cart-tiem_summary
    text-size-adjust: 100%
    // font-size: 0.875rem
    // line-height: 1rem
    // border-top: 1px solid $grey
    display: flex
    justify-content: space-between
    // font-weight: 700
