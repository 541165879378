$oi-text-color: #2E1C00

.modal--cart
    .modal-content
        overflow: visible

// .modal-background
//     // background-color: rgba(10, 10, 10, 0.86);
//     background-color: rgba(#2E1C00, 0.7);

.modal
    &.modal--cart
        .modal-content
            width: auto
            margin: 0 0 0 auto
            max-height: 100vh
            max-height: 100dvh
            height: 100vh
            height: 100dvh
        .modal-background
            // background-image: linear-gradient(180deg, #FFEAA5 0%, #FFDA61 100%)
            background-image: linear-gradient(180deg, rgba(#FFEAA5, 0.4) 0%, rgba(#FFDA61, 0.4) 100%)
            background-color: rgba(255, 234, 165, 0.4)
        .modal-close
            transition: all 0.3s
        .modal-close:hover, .modal-close:focus
            background-color: rgba(255, 255, 255, 0.6)
        .modal-close::before, .modal-close::after
            background-color: $gray-prime

.modal__cart
    display: flex
    // align-items: center
    position: relative
    background-color: #FFF
    width: 22rem
    height: 100vh
    height: 100dvh
    // border-radius: 50%
    box-shadow: 0 5px 15px 0 rgba(89,69,45,0.05)
    // https://demo.saleor.io/product/colored-parrot-cushion/?cushion-size=45cm%20x%2045cm
    flex-direction: column
    // -webkit-box-pack: justify;
    justify-content: space-between
    // width: 26rem;
    // max-width: 100vw;
    // box-shadow: rgb(0 0 0 / 15%) 6px 0px 30px;

    // border-top: 100vh solid transparent;
    // border-right: 50vw solid #1B1F25;
    // border-left: 50vw solid #fff;
    // box-shadow: 2px 2px 20px 0 rgba(255,255,255,0.05);
    // &::before
    //     content: ""
    //     position: absolute
    //     width: 116%
    //     height: 116%
    //     left: -8%
    //     top: -8%
    //     border: 1px solid rgba(255, 255, 255, 0.6)
    //     border-radius: 50%
    //     // background: #000
    +tablet
        width: 26rem
        // height: 100vh
    .cart__content
        overflow: auto
        height: 100%
        // margin: 0 auto
        // z-index: 1
        // padding: 3rem 3rem 5rem 3rem
        color: rgba($oi-text-color, 0.81)
        // padding: 0 3rem
        // @media screen and (min-width: 450px)
    +tablet
        .cart__content
            max-width: 100%
            // padding: 3rem 3rem 5rem 3rem
            // padding: 0 4rem

.cart__header
    height: calc( 2rem + 40px )
    min-height: calc( 2rem + 40px )
    display: flex
    align-items: center
    border-bottom: 1px solid $cart-border--color

.cart__title
    font-size: 1.2rem
    line-height: 1.3
    font-weight: 700
    // text-align: center
    margin-bottom: 0rem
    margin-left: var(--cart-margin--horizontal)
    margin-right: var(--cart-margin--horizontal)
    // color: $watermelon

// .cart__form-wrap
//     label
//         display: none
//     .message.is-success
//         text-align: center

.cart__footer
    background-color: rgb(241, 245, 245)
    // padding: $cart-margin--vertical $cart-margin--horizontal
    padding: var(--cart-margin--vertical) var(--cart-margin--horizontal)
.cart__total
    text-size-adjust: 100%
    font-size: 0.875rem
    line-height: 1rem
    // border-top: 1px solid $grey
    display: flex
    justify-content: space-between
    font-weight: 700
    // padding-top: 0.75rem
    // padding-bottom: 0.75rem
    &:not(:last-child)
        margin-bottom: 0.5rem
.cart__goto-checkout
    display: block
    text-align: center
    // text-transform: uppercase
    // margin-top: 0.5rem
    width: 100%
    line-height: 1
    padding: 1rem 1.5rem
    font-size: 1rem
    color: #273437
    font-weight: 600
    background-color: #ffde17
    // background: linear-gradient(90deg, #ffde17 0%, #FFA800 100%) !important
    border: 2px solid #ffde17
    transition: all 0.3s
    outline: none
    &:hover
        color: #000

.cart-item__remove
    align-self: flex-end
    // margin-left: 20px
    flex-grow: 1
    max-width: 70px
.button--remove
    transition: all 0.3s
    height: 2.5rem
    width: 2.5rem
    display: block
    margin-left: auto
    margin-right: auto
    padding: 0
    background: none
    border: 0px
    color: $input-border-color
    &:hover
        color: $watermelon
