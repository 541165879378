// #FFDE17
$yellow: rgb(255, 222, 23)
// #FF4D6B
$watermelon: rgb(255, 77, 107)

// #273437 - меню в шапці
$gray-prime: rgb(39, 52, 55)

// #272343 - основний текст
$gray-blue: rgb(39, 35, 67)

// #818A91
$light-blue: rgb(223, 241, 244)

// #FFAF11
$orange: rgb(100%, 69%, 7%)

$dark1: #20201e
$dark-red: #c00a27
$light-red: #f86e6e

// $active-color-border: #948C88
$active-color-border: rgba(50,50,50)

// 4C4B49
$gray-price: #4C4B49
// Colors
$black:        hsl(0, 0%, 4%) !default
$black-bis:    hsl(0, 0%, 7%) !default
$black-ter:    hsl(0, 0%, 14%) !default

$grey-darker:  hsl(0, 0%, 21%) !default
$grey-dark:    hsl(0, 0%, 29%) !default
$grey:         hsl(0, 0%, 48%) !default
$grey-light:   hsl(0, 0%, 71%) !default
$grey-lighter: hsl(0, 0%, 86%) !default
$grey-lightest: hsl(0, 0%, 93%) !default

$white-ter:    hsl(0, 0%, 96%) !default
$white-bis:    hsl(0, 0%, 98%) !default
$white:        hsl(0, 0%, 100%) !default

$orange:       hsl(14,  100%, 53%) !default
// $yellow:       hsl(44,  100%, 77%) !default
$green:        hsl(153, 53%,  53%) !default
$turquoise:    hsl(171, 100%, 41%) !default
$cyan:         hsl(207, 61%,  53%) !default
$blue:         hsl(229, 53%,  53%) !default
$purple:       hsl(271, 100%, 71%) !default
$red:          hsl(348, 86%, 61%) !default

// Typography

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
// $family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default
$family-sans-serif: 'Montserrat', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default
$family-monospace: monospace !default
$render-mode: optimizeLegibility !default

$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 1rem !default
$size-7: 0.75rem !default

$weight-light: 300 !default
$weight-normal: 400 !default
$weight-medium: 500 !default
$weight-semibold: 600 !default
$weight-bold: 700 !default

// Spacing

$block-spacing: 1.5rem !default

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default
$widescreen-enabled: true !default
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default
$fullhd-enabled: false !default

// Miscellaneous

$easing: ease-out !default
$radius-small: 2px !default
$radius: 4px !default
$radius-large: 6px !default
$radius-rounded: 9999px !default
$speed: 86ms !default

// Flags

$variable-columns: true !default
$rtl: false !default

$box-shadow-hover1: 0 5px 15px 0 rgba(89,69,45,0.1)

// rgba(255,253,246, 0.98) - дуже світлий і прозорий
$modal-background-background-color: rgba(255,234,165, 0.9)

$gray-text-light: $grey-light

$cart-margin--horizontal: 1.875rem
$cart-margin--vertical: 1rem
$cart-border--color: rgb(241, 245, 245)


// $yellow-1: #ffd300
$yellow-1: #ffc107
:root
    --cart-margin--horizontal: 1.875rem
    --cart-margin--vertical: 1rem
    --yellow-1: #ffc107

// derived-variables.sass
$link: $watermelon
$link-hover: rgba(255,77,107,0.9)

