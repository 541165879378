$cart-image--width: 70px

.cart-item
    display: flex
    flex-wrap: wrap
    // padding: $cart-margin--vertical $cart-margin--horizontal
    // var(--heading_color, black)
    padding: var(--cart-margin--vertical) var(--cart-margin--horizontal)
    border-bottom: 1px solid $cart-border--color
    .product-thumbnail
        width: $cart-image--width
        img
            display: block
            width: 100%
            height: auto
    .product-name
        margin-bottom: 0.5rem
        a
            font-size: 1rem
            font-weight: 600
            color: $dark1
    .product-detail
        padding-left: 1rem
        width: calc(100% - 70px)
    .variation-info
        color: $dark1
        font-size: 0.875rem
    .variation_label
        // color: $grey-light
        color: $grey
    .cart-item__counter
        margin-left: auto
        padding-top: 0.5rem
    .counter__label
        color: $grey
    .product-total
        padding-left: 2rem
        align-self: flex-end
        padding-bottom: 0.5rem
    .price-title
        display: none
    .price-old
        position: absolute
        top: 0
        left: 0
        // font-weight: 400;
        // font-size: 1rem;
        // color: #44403F;

// counter
.total__counter
    line-height: 1
    color: $grey-darker

.counter
    display: flex
    align-items: flex-end
    font-size: 1.2rem
    font-weight: 600

$input-border-color1: rgba($grey-lightest, 0.9)
.count
    display: flex
    align-items: center
    border: 1px solid $input-border-color1
    border-radius: 2px
    background-color: #fff
    button
        border: none
        background-color: #fff
        font-size: 1.2rem
        font-weight: 600
        // color: $watermelon
        color: $grey-dark
        cursor: pointer
        line-height: 2.5rem
        padding: 0
        // padding: 0 0.81rem
        width: 2.5rem
        text-align: center
        background-color: rgba($grey-lightest, 0.15)
    .count__value
        display: block
        border-left: 1px solid $input-border-color1
        border-right: 1px solid $input-border-color1
        line-height: 2.5rem
        // padding-left: 1.2rem
        // padding-right: 1.2rem
        width: 2.5rem
        text-align: center
        +desktop
            // padding-left: 1.7rem
            // padding-right: 1.7rem
            width: 4rem

.counter__label
    font-size: 0.875rem
    font-weight: 500
    padding-bottom: 0.3rem
.count__label
    font-size: 1.2rem
    font-weight: 600
    padding-left: 0.875rem
    padding-bottom: 0.3rem
