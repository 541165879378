/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 10px !default
$hamburger-padding-y: 10px !default
$hamburger-layer-width: 40px !default
$hamburger-layer-height: 4px !default
$hamburger-layer-spacing: 6px !default
// $hamburger-layer-color: rgba(#FFF, 0.8) !default
$hamburger-layer-color: #273437 !default
$hamburger-layer-border-radius: 4px !default
$hamburger-hover-opacity: 0.7 !default
$hamburger-active-layer-color: #FFF !default
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default
$hamburger-hover-filter: opacity(50%) !default
$hamburger-active-hover-filter: $hamburger-hover-filter !default

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (collapse) !default
// 3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   3dxy,
//   3dxy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   arrowturn,
//   arrowturn-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   minus,
//   slider,
//   slider-r,
//   spin,
//   spin-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
//   squeeze,
//   vortex,
//   vortex-r

// Base Hamburger (We need this)
// ==================================================
// @import "base";
@import "/node_modules/hamburgers/_sass/hamburgers/base"

// Hamburger types
// ==================================================
// @import "/node_modules/hamburgers/_sass/hamburgers/types/3dx"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/3dx-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/3dy"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/3dy-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/3dxy"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/3dxy-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/arrow"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/arrow-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/arrowalt"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/arrowalt-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/arrowturn"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/arrowturn-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/boring"
@import "/node_modules/hamburgers/_sass/hamburgers/types/collapse"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/collapse-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/elastic"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/elastic-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/emphatic"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/emphatic-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/minus"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/slider"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/slider-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/spin"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/spin-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/spring"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/spring-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/stand"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/stand-r"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/squeeze"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/vortex"
// @import "/node_modules/hamburgers/_sass/hamburgers/types/vortex-r"

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
